<template>
    <div class="truck-sub-wrap">
        <div class="fix-content-wrap">
            <div
                :class="{
                    'fix-menu-list': true,
                    'complete-collapse': isCollapse,
                    'no-collapse': !isCollapse,
                }"
            >
                <el-menu
                    :default-active="curSelectMenu"
                    @select="handleMenuSelect"
                >
                    <el-menu-item
                        index="subscribe"
                        class="hvr-bounce-to-bottom"
                    >
                        <div class="flex-wrap">
                            <div class="icon-wrap flex-wrap">
                                <MyIcon
                                    icon="tdesign:map-information"
                                    :size="20"
                                ></MyIcon>
                            </div>
                            <span v-if="!isCollapse" class="menu-title">
                                集卡订阅
                            </span>
                        </div>
                    </el-menu-item>
                    <el-menu-item index="list" class="hvr-bounce-to-bottom">
                        <div class="flex-wrap">
                            <div class="icon-wrap flex-wrap">
                                <MyIcon
                                    icon="tdesign:root-list"
                                    :size="20"
                                ></MyIcon>
                            </div>
                            <span v-if="!isCollapse" class="menu-title">
                                订阅列表
                            </span>
                        </div>
                    </el-menu-item>
                </el-menu>
                <div :class="{ 'menu-footer': true, vertical: isCollapse }">
                    <div>
                        <el-button
                            type="primary"
                            size="mini"
                            @click="handleShowServiceAgreement"
                        >
                            <div class="flex-wrap">
                                <MyIcon
                                    icon="ant-design:file-text-outlined"
                                    :size="14"
                                ></MyIcon>
                                <span
                                    v-if="!isCollapse"
                                    style="margin-left: 4px"
                                >
                                    服务协议
                                </span>
                            </div>
                        </el-button>
                        <el-button
                            type="warning"
                            size="mini"
                            @click="handleShowNoTruckTips"
                            :style="{
                                marginBottom: isCollapse ? '8px' : '0px',
                            }"
                        >
                            <div class="flex-wrap">
                                <MyIcon
                                    icon="ant-design:question-outlined"
                                    :size="14"
                                ></MyIcon>
                                <span
                                    v-if="!isCollapse"
                                    style="margin-left: 4px"
                                >
                                    查不出轨迹?
                                </span>
                            </div>
                        </el-button>
                    </div>

                    <el-button
                        type="default"
                        size="mini"
                        @click="handleMenuCollapse"
                    >
                        <div class="flex-wrap">
                            <div
                                :class="
                                    isCollapse
                                        ? 'collapse-icon flex-wrap'
                                        : 'flex-wrap'
                                "
                            >
                                <MyIcon
                                    icon="ant-design:menu-fold-outlined"
                                    :size="14"
                                ></MyIcon>
                            </div>
                        </div>
                    </el-button>
                </div>
            </div>
            <div class="fix-operation-wrap" v-if="curSelectMenu">
                <div
                    class="operation-sub-form"
                    v-if="curSelectMenu === 'subscribe'"
                >
                    <el-form
                        :model="formData"
                        :inline="true"
                        :rules="formRules"
                        ref="subscribeFormRef"
                        :show-message="false"
                        size="small"
                    >
                        <el-form-item prop="blno">
                            <el-input
                                v-model="formData.blno"
                                placeholder="提单号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="truckLicense">
                            <el-input
                                v-model="formData.truckLicense"
                                placeholder="车牌号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                :loading="submitLoading"
                                type="primary"
                                @click="handleSubmit"
                            >
                                订阅
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <div class="subscribe-tooltip-wrap">
                        <div>
                            注意：订阅功能为
                            <span>收费项目</span>
                            ， 收费标准为：
                            <span>1火币/车牌</span>
                        </div>
                        <div>
                            （订阅有效期为
                            <span>1天</span>
                            ， 1天内重复查询不收费，每天有
                            <span>2个车牌</span>
                            的免费额度）
                        </div>
                    </div>
                </div>
                <div
                    class="truck-subscribe-list"
                    v-if="curSelectMenu === 'list'"
                >
                    <el-row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        style="padding-right: 4px"
                    >
                        <el-form :inline="true" size="small">
                            <el-form-item>
                                <el-input
                                    v-model="listFilterFormData.blno"
                                    clearable
                                    @clear="loadSubList"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="loadSubList">
                                    搜索
                                </el-button>
                            </el-form-item>
                        </el-form>
                        <div
                            style="padding: 8px"
                            @click="handleCloseMenu('list')"
                        >
                            <i class="el-icon-error"></i>
                        </div>
                    </el-row>
                    <el-table
                        :data="recentList"
                        size="small"
                        max-height="200px"
                        v-loading="listLoading"
                    >
                        <el-table-column
                            v-for="col in columns"
                            :key="col.prop"
                            :label="col.label"
                            :prop="col.prop"
                            :min-width="col.width"
                        ></el-table-column>
                        <el-table-column label="操作" width="190" fixed="right">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    @click="handleShowTrack(scope.row)"
                                >
                                    查看轨迹
                                </el-button>
                                <el-button
                                    type="text"
                                    @click="handleUpdateRemark(scope.row)"
                                >
                                    修改备注
                                </el-button>
                                <el-button
                                    type="text"
                                    class="danger-btn"
                                    style="margin-left: 8px"
                                    @click="handleDelete(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div
            class="fix-truckLicense"
            v-if="curTruckLicense && curAddressInfo.showAddress"
        >
            <el-collapse v-model="fixLicenseCollapse">
                <el-collapse-item name="license">
                    <template #title>
                        <div class="fix-title">车牌：{{ curTruckLicense }}</div>
                    </template>
                    <div class="desc-wrap">
                        <div class="desc-item">
                            当前位置：{{ curAddressInfo.showAddress }}
                        </div>
                        <div class="desc-item">
                            最后更新时间：{{ lastUpdateTime }}
                        </div>
                        <div class="desc-item">
                            当前车速：{{ curTruckSpeed }}km/h
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <service-agreement ref="serviceAgreeRef"></service-agreement>
        <NoTruckTips ref="noTruckTipsRef" />
        <expired-dialog
            ref="expiredRef"
            @refresh="loadSubList"
        ></expired-dialog>
        <remark-dialog
            ref="remarkRef"
            @confirm="loadSubList"
            showTab="NB"
        ></remark-dialog>
        <div id="mapDiv"></div>
    </div>
</template>

<script>
import MyIcon from "@/components/common/MyIcon.vue";
import { apiCallback } from "@/utils";
import { getCarTrajectory, getCarSubscribeList } from "@/api/truckSubscribe";
import ServiceAgreement from "./components/ServiceAgreement.vue";
import ExpiredDialog from "./components/ExpiredDialog.vue";
import NoTruckTips from "./components/NoTruckTips.vue";
import RemarkDialog from "@/components/ship/RemarkDialog.vue";
import moment from "moment";
import { deleteCarSubscribe } from "../../api/truckSubscribe";

const columns = [
    {
        label: "提单号",
        prop: "blno",
        width: 150,
    },
    {
        label: "车牌号",
        prop: "truckLicense",
        width: 80,
    },
    {
        label: "备注",
        prop: "remark",
        width: 150,
    },
];

const formRules = {
    truckLicense: [{ required: true, message: "车牌号必填" }],
};

export default {
    name: "truckSubscribe",
    components: {
        MyIcon,
        ServiceAgreement,
        ExpiredDialog,
        RemarkDialog,
        NoTruckTips,
    },
    data() {
        return {
            columns,
            formRules,
            listLoading: false,
            submitLoading: false,
            delPopVisibleEnum: {},
            isCollapse: false,
            curSelectMenu: "subscribe",
            formData: {
                blno: "",
                truckLicense: "",
            }, // 添加订阅
            recentList: [], // 订阅列表
            listFilterFormData: {
                blno: "",
            },
            boxPosition: [], // 提单号关联的车牌号列表
            expiredTruckList: [], // 订阅失效的车牌列表
            lineArr: [], // 轨迹经纬度列表
            wholeLineArr: [], // 完整的轨迹信息（包括车速和更新时间）
            curAddressInfo: {},
            map: null,
            curTruckLicense: "", // 当前显示车牌
            lastUpdateTime: "", // 最后更新时间
            curTruckSpeed: "", // 当前车速
            huoche: require("@/assets/img/huoche2.png"),
            startIcon:
                "https://web.nbhuojian.com/static/fangcangwang/map/start.png", //起点图标
            endIcon:
                "https://web.nbhuojian.com/static/fangcangwang/map/end.png", //终点图标
            fixLicenseCollapse: ["license"], // 右上角车牌信息是否展开
        };
    },
    mounted() {
        this.initMap();
        if (this.$route.params.blno) {
            this.listFilterFormData.blno = this.$route.params.blno;
            this.boxPosition = this.$route.params.boxPosition;
            this.curSelectMenu = "list";
            this.$nextTick(() => {
                this.loadSubList(true);
            });
        }
    },
    methods: {
        // 找出订阅失效的车牌
        checkExpired(newList) {
            const result = this.boxPosition.filter((item) => {
                const isExpired = newList.some((list) => {
                    return list.truckLicense === item;
                });
                return !isExpired;
            });
            return result;
        },
        getShowSpeed(time, speed) {
            const timeDiff = moment(time).diff(moment(), "minutes");
            let showSpeed;
            if (timeDiff === 0) {
                showSpeed = speed;
            } else {
                showSpeed = 0;
            }
            return showSpeed;
        },
        loadSubList(fromMount) {
            const that = this;
            this.listLoading = true;
            getCarSubscribeList({
                ...this.listFilterFormData,
            })
                .then((res) => {
                    const data = apiCallback(res);
                    this.recentList = data;
                    this.delPopVisibleEnum = data.reduce((acc, cur) => {
                        acc[cur.id] = false;
                        return acc;
                    }, {});
                    if (fromMount === true) {
                        const expiredList = this.checkExpired(data);
                        if (expiredList.length) {
                            that.$refs.expiredRef.open({
                                list: expiredList,
                                blno: that.$route.params.blno,
                            });
                        }
                    }
                })
                .finally(() => {
                    that.listLoading = false;
                });
        },
        loadCarTrajectory(data) {
            this.submitLoading = true;
            const reqParam = data
                ? {
                      blno: data.blno,
                      truckLicense: data.truckLicense,
                  }
                : { ...this.formData };
            if (this.$store.state.user.cid) {
                reqParam.companyId = this.$store.state.user.cid;
            }
            getCarTrajectory(reqParam)
                .then((res) => {
                    const data = apiCallback(res);
                    if (!data || !data.length) {
                        // this.$alert("当前车牌暂未查询到数据！", "提示");
                        this.$AlertTipsDialog({
                            msg: "当前车牌暂未查询到数据！",
                        });
                        this.initMap();
                    } else {
                        this.curTruckLicense = reqParam.truckLicense;
                        this.wholeLineArr = data.slice(-300);
                        const lastTarData = data.slice(-1)[0];
                        this.lastUpdateTime = lastTarData.time;
                        this.curTruckSpeed = this.getShowSpeed(
                            lastTarData.time,
                            lastTarData.speed,
                        );
                        this.showTruckTrack(data.slice(-300));
                    }
                })
                .finally(() => {
                    this.submitLoading = false;
                });
        },
        handleDelete(row) {
            // console.log(this.$DeleteConfirmDialog)
            this.$DeleteConfirmDialog({
                msg: `您确定删除车牌 ${row.truckLicense} 吗？`,
                deleteVoid: () => {
                    return deleteCarSubscribe({ id: row.id });
                },
                callBack: () => {
                    this.loadSubList();
                },
                successMsg: "车牌删除成功",
            });
            // this.$confirm(`您确定删除车牌 ${row.truckLicense} 吗？`, '提示', {
            //     callback: (action) => {
            //         if (action === 'confirm') {
            //             this.handleConfirmDelete(row)
            //         }
            //     }
            // })
        },
        handleMenuCollapse() {
            this.isCollapse = !this.isCollapse;
        },
        handleMenuSelect(menu) {
            this.curSelectMenu = menu;
            if (menu === "list") {
                this.loadSubList();
            }
        },
        handleSubmit() {
            this.$refs.subscribeFormRef.validate((validate) => {
                if (validate === true) {
                    this.loadCarTrajectory();
                } else {
                    this.$message.error("车牌号不能为空！");
                }
            });
        },
        handleShowServiceAgreement() {
            this.$refs.serviceAgreeRef.open();
        },
        handleShowNoTruckTips() {
            this.$refs.noTruckTipsRef.open();
        },
        carpassOneNodeRecord(lnglat, index, length) {
            let lineArr = this.lineArr;
            if (index === length) {
                this._CarTrack.pause();
                //添加起始点
                var startMarker = new T.Marker(
                    new T.LngLat(lineArr[0][0], lineArr[0][1]),
                    {
                        icon: new T.Icon({
                            iconUrl: this.startIcon,
                            iconSize: new T.Point(44, 34),
                            iconAnchor: new T.Point(12, 31),
                        }),
                    },
                );
                this.map.addOverLay(startMarker);
                var endMarker = new T.Marker(
                    new T.LngLat(
                        lineArr[lineArr.length - 1][0],
                        lineArr[lineArr.length - 1][1],
                    ),
                    {
                        icon: new T.Icon({
                            iconUrl: this.endIcon,
                            iconSize: new T.Point(44, 34),
                            iconAnchor: new T.Point(12, 31),
                        }),
                    },
                );
                this.map.addOverLay(endMarker);
            }
        },
        addTrajectorySeppdInfo(onlyOne) {
            const startLine = this.wholeLineArr[0];
            const endLine = this.wholeLineArr[this.wholeLineArr.length - 1];
            let lineLabelArr = [];
            if (onlyOne) {
                lineLabelArr = [endLine];
            } else {
                lineLabelArr = [startLine, endLine];
            }
            lineLabelArr.forEach((item, index) => {
                const curLnglat = new T.LngLat(item.lon, item.lat);
                const geocode = new T.Geocoder();
                geocode.getLocation(curLnglat, (result) => {
                    const { addressComponent } = result;
                    const { province, city, county, address, poi } =
                        addressComponent;
                    const pointTitle = index === 0 ? "起点" : "当前位置";
                    const appendStr =
                        address === poi ? address : `${address}(${poi})`;
                    const showAddress = `${province}${city}${county}${appendStr}`;
                    const curLineLabel = new T.Label({
                        text: `<div class='way-point'><div>${pointTitle}:${showAddress}</div><div class='secondary-info'>时间:${item.time}</div><div class='secondary-info'>速度:${item.speed}km/h</div></div>`,
                        position: curLnglat,
                        offset: new T.Point(-100, -100),
                    });
                    if (!onlyOne && index === 1) {
                        this.curAddressInfo = {
                            ...addressComponent,
                            showAddress,
                        };
                    } else if (onlyOne && index === 0) {
                        this.curAddressInfo = {
                            ...addressComponent,
                            showAddress,
                        };
                    }
                    this.map.addOverLay(curLineLabel);
                });
            });
        },
        searchResult(result) {
            if (result.start === result.end) {
                return;
            }
            this.map.clearOverLays();
            //添加起始点
            var startMarker = new T.Marker(result.getStart(), {
                icon: new T.Icon({
                    iconUrl: this.startIcon,
                    iconSize: new T.Point(44, 34),
                    iconAnchor: new T.Point(12, 31),
                }),
            });
            this.map.addOverLay(startMarker);
            var endMarker = new T.Marker(result.getEnd(), {
                icon: new T.Icon({
                    iconUrl: this.endIcon,
                    iconSize: new T.Point(44, 34),
                    iconAnchor: new T.Point(12, 31),
                }),
            });
            // console.log(result.getEnd(),'-----end')
            this.map.addOverLay(endMarker);
            this.addTrajectorySeppdInfo();
            //获取方案个数
            var routes = result.getNumPlans();
            for (var i = 0; i < routes; i++) {
                //获得单条驾车方案结果对象
                var plan = result.getPlan(i);
                // const cacheData = this.lineArr.map(item => {
                //     return new T.LngLat(item[0],item[1])
                // })
                this._CarTrack = new T.CarTrack(this.map, {
                    interval: 5,
                    speed: 10,
                    dynamicLine: false,
                    carstyle: {
                        display: true,
                        iconUrl: this.huoche,
                        width: 80,
                        height: 80,
                    },
                    Datas: plan.getPath(),
                    // Datas: cacheData,
                    polylinestyle: {
                        color: "red",
                        weight: 2,
                        width: 6,
                        opacity: 0.8,
                    },
                    passOneNode: this.carpassOneNodeRecord,
                });
            }
        },
        startAnimation() {
            if (!this.lineArr.length) return;
            let lineArr = this.lineArr;
            if (this._CarTrack) {
                this._CarTrack.clear();
            }
            var startLngLat = new T.LngLat(lineArr[0][0], lineArr[0][1]);
            var endLngLat = new T.LngLat(
                lineArr[lineArr.length - 1][0],
                lineArr[lineArr.length - 1][1],
            );
            if (
                startLngLat.lat == endLngLat.lat &&
                startLngLat.lng == endLngLat.lng
            ) {
                this.addTrajectorySeppdInfo(true);
                return;
            }
            let config = {
                policy: 0, //驾车策略
                onSearchComplete: this.searchResult, //检索完成后的回调函数
            };
            let drivingRoute = new T.DrivingRoute(this.map, config);
            //驾车路线搜索
            drivingRoute.search(startLngLat, endLngLat);
            setTimeout(() => {
                this._CarTrack && this._CarTrack.start();
            }, 300);
        },
        showTruckTrack(data) {
            let that = this;
            var lineArr = [];
            if (data.length) {
                for (var i = 0; i < data.length; i = i + 1) {
                    var lonlat = [];
                    lonlat[0] = data[i]["lon"];
                    lonlat[1] = data[i]["lat"];
                    lineArr.push(lonlat);
                }
            }
            this.lineArr = lineArr;
            // 初始化地图
            let map = that.map;
            map.centerAndZoom(
                new T.LngLat(
                    lineArr[lineArr.length - 1][0],
                    lineArr[lineArr.length - 1][1],
                ),
                12,
            );
            //创建图片对象
            var icon = new T.Icon({
                iconUrl: that.huoche,
                iconSize: new T.Point(80, 80),
            });
            //创建标注对象
            let endMarker = new T.Marker(
                new T.LngLat(
                    lineArr[lineArr.length - 1][0],
                    lineArr[lineArr.length - 1][1],
                ),
                {
                    icon: icon,
                },
            );
            //向地图上添加标注
            map.addOverLay(endMarker);
            this.startAnimation();
        },
        initMap() {
            this.lastUpdateTime = "";
            this.curTruckSpeed = "";
            this.curAddressInfo = {};
            this.map = new T.Map("mapDiv", { minZoom: 4 });
            this.map.centerAndZoom(new T.LngLat(116.40769, 39.89945), 12);
        },
        handleShowTrack(row) {
            this.curTruckLicense = "";
            this.lastUpdateTime = "";
            this.curTruckSpeed = "";
            this.curAddressInfo = {};
            this.loadCarTrajectory(row);
        },
        handleUpdateRemark(row) {
            this.$refs.remarkRef.initCar(row);
        },
        handleCloseMenu(type) {
            this.curSelectMenu = "";
        },
    },
};
</script>

<style lang="stylus">
@keyframes shrink {
    from {
        width: 15vw;
    }
    to {
        width: 64px;
    }
}
@keyframes expand {
    from {
        width: 64px;
    }
    to {
        width: 15vw;
    }
}
.truck-sub-wrap{
    .hvr-bounce-to-bottom {
        width:100%;
        display: flex;
        align-items:center;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        border-radius: 4px;
        color: #333333;
        border: none;
        font-size:15px;
        font-weight:bold;
        overflow: hidden;
        &.is-active{
          border-radius: 2px 2px 0 0;
          border-left: 5px solid #A91520;
          background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
          color:#8B0505 !important;
          & i{
            color:#8B0505 !important;
          }
        }
      }

      /deep/  .el-submenu__title{
        color:#333333;
        font-size:18px;
        font-weight:bold;
      }

    .tdt-control-copyright.tdt-control{
        display: none;
    }
    #mapDiv{
        width: 100%;
        height: 100%;
    }

    .fix-truckLicense{
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 1999;
        background: #ffffff;
        padding-left: 8px;
        border-radius: 4px;
        overflow: hidden;
        .el-collapse-item__header{
            height: 36px;
            line-height: 39px;
        }
        .el-collapse-item__content{
            padding-bottom: 0;
        }
        .fix-title{
            font-size: 14px;
            font-weight: bold;
            margin-right: 24px;
        }
        .desc-wrap{
        }
        .desc-item{
            max-width: 250px;
            margin-bottom: 4px;
            font-size: 12px;
            font-weight: 400;
            color:rgb(122, 134, 153);
            word-break: break-all;
            word-wrap: break-word;
            white-space: normal;
        }
    }
    .el-button--mini{
        padding: 4px 8px;
    }
    .flex-wrap{
        display: flex;
        align-items: center;
        span{
            margin-left: 4px;
        }
    }
    .icon-wrap{
        height:56px;
    }
    height:100%;
    width:100%;
    position: relative;
    .fix-content-wrap{
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
    }
    .fix-operation-wrap {
        margin-top: 16px;
        margin-left: 16px;
        height: fit-content;
        min-width: 200px;
        background: #ffffff;
        padding: 12px 2px 12px 12px;
        border-radius: 4px;
        overflow:hidden;
        z-index: 1999;
        .el-form-item{
            margin-bottom:0;
        }
    }
    .fix-menu-list{
        height:100%;
        background: #ffffff;
        position:relative;
        z-index: 1999;
        .el-menu{
            border-right: none;
        }
        &.complete-collapse{
            width: 64px;
            animation-name: shrink;
            animation-duration: .3s;
        }
        &.no-collapse{
            width: 15vw;
            animation-name: expand;
            animation-duration: .3s;
        }
    }
    #map-div{
        width:100%;
        height:100%;
        background: #efefef;
    }
    .truck-subscribe-list{
        width: 30vw;
        min-width: 450px;
        z-index: 1999;
        i{
            display: block;
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: #606266;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
        }
        .el-button--text{
            padding: 0
        }
        .cell{
            width: fit-content;
            white-space:nowrap;
        }
    }
    .menu-footer{
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        position: absolute;
        bottom: 8px;
        display: flex;
        justify-content: space-between;
        &.vertical{
            flex-direction: column;
            .el-button{
                margin-left: 0;
                &:first-child{
                    margin-bottom: 8px;
                }
            }
        }
        .el-button{
            width: fit-content;
        }
    }
    .collapse-icon{
        transform: rotate(180deg);
    }
    .subscribe-tooltip-wrap{
        font-size:12px;
        margin-top: 8px;
        span{
            color: #F56C6C;
            font-weight:bold;
        }
    }
    .way-point{
        width: 200px;
        padding-top: 4px;
        div{
            margin-bottom: 4px;
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
            word-break: break-all;
            word-wrap: break-word;
            white-space: normal;
        }
        .secondary-info{
            font-weight: 400;
            color: #7a8699;
        }
    }
}
</style>
